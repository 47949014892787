import QRCodeStyling from 'qr-code-styling'
import { gsap } from 'gsap'
export default class QR {
    constructor() {
        this.size = 0;
        this.x = 0;
        this.y = 0;
        this.scale = 1
        this.alpha = 0
        this.qrImage = null;
        this.code = new QRCodeStyling({
            width: 100,
            height: 100,
            type: "svg",
            data: "https://spaceboy.mx/",
            // data: window.location.href,
            image: "/spaceboy-logo.svg",
            dotsOptions: {
                color: "#FFF",
                type: "square"
            },
            backgroundOptions: {
                color: "transparent",
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 5
            }
        });

        // const img = new Image();
        // this.code.getRawData("svg").then((data) => {
        //     img.src = URL.createObjectURL(data)
        //     //"#"
        //     img.onload = () => {
        //         this.qrImage = img
        //         this.setTL()
        //     }
        // })
        // this.shareBtn = document.getElementById("share-page-btn")
        // this.code.append(this.shareBtn)
        // this.qrImage = this.shareBtn.querySelector("svg")
        this.insertQRCode();
    }

    insertQRCode() {
        this.code.getRawData("svg").then((data) => {
            const url = URL.createObjectURL(data);
            const img = new Image()
            img.onload = () => {
                this.qrImage = img
                URL.revokeObjectURL(url)
                this.appendSVGToDOM(img)
                // una vez que se carga la imagen, se ejecuta
                this.intro()
            };
            img.src = url;
        });
    }

    appendSVGToDOM(img) {
        this.sharePageBtn = document.getElementById("share-page-btn");
        if (this.sharePageBtn) return this.sharePageBtn.appendChild(img)
    }

    onResize(_size) {
        this.size = _size;
        this.x = window.HALF_X - this.size / 2
        this.y = window.HALF_Y - this.size / 2
    }

    intro() {
        this.tl = gsap.timeline()
        // .set(this.sharePageBtn.querySelector('img'), { 
        //     opacity: 0
        // }, 0)
        this.tl.to(this, { 
            scale: 2,
            alpha: 1,
            duration: .55,
            ease: "steps(3)"
        }, 0)
        .set(this, { 
            alpha: 0
        }, .6)
        .from(this.sharePageBtn.querySelector('img'), { 
            opacity: 0,
            scaleX: 0,
            scaleY: 0,
            duration: .5,
            ease: "steps(3)"
        }, .85)
    }

    show() {
        if (this.qrImage) return this.tl.play()
    }

    draw(_ctx) {
        if (this.qrImage) {
            this.center(_ctx)
        }
    }

    center(_ctx) {
        const scaledSize = this.size * this.scale
        const offsetX = (scaledSize - this.size) / 2
        const offsetY = (scaledSize - this.size) / 2
        _ctx.save()
        _ctx.globalAlpha = this.alpha
        _ctx.drawImage(this.qrImage, this.x - offsetX, this.y - offsetY, scaledSize, scaledSize)
        _ctx.restore()
    }
}