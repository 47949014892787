import Canvas from '../canvas'
import Text from './text'
import QR from './qr'
import Frame from './frame'
import Video from './video'
export default class Opening extends Canvas {
    constructor(_container) {
        super(_container)
        this.setTexts()
        //
        this.qr = new QR()
        this.video = new Video()
        //
        this.have_frame = this.container.classList.contains('frame')
        this.frame = this.setFrame()
    }

    setFrame() {
        if (this.have_frame) return new Frame()
        else return null
    }

    setTexts() {
        const title = this.container.querySelector('.title')
        if (title) this.title = new Text(title, {
            lineWidth: 2
        })
        const subtitle = this.container.querySelector('.subtitle')
        if (subtitle) this.subtitle = new Text(subtitle, {
            lineWidth: .5
        })
    }

    show() {
        if (this.frame) this.frame.show()
        if (this.title) this.title.show(1)
        if (this.subtitle) {
            gsap.delayedCall(.5, () => {
                this.subtitle.show(.5)
            })
        }
    }

    render() {
        this.clear()
        this.context.lineWidth = 1
        this.context.strokeStyle = '#FFF'
        this.context.fillStyle = '#FFF'
        //
        this.qr.draw(this.context)
        if (this.title) this.title.draw(this.context)
        if (this.subtitle) this.subtitle.draw(this.context)
        if (this.frame) this.frame.draw(this.context)
        // frame
    }

    onResize() {
        this.setSize(window.WIDTH, window.HEIGHT)
        this.clear()
        //
        if (this.title) this.title.onResize()
        if (this.subtitle) this.subtitle.onResize()
        if (this.qr) this.qr.onResize(window.WIDTH * .2)
    }

    get rect() {
        return this.container.getBoundingClientRect()
    }

}