export default class Video {
    constructor() {
        this.video = document.querySelector("#hero-video-case")
        this.togglePlayBtn = document.querySelector("#toggle-play-btn")
        this.preview = document.querySelector("#hero-video-preview")
        this.addEvents()
    }

    addEvents() {
        if (!this.togglePlayBtn) return false
        this.togglePlayBtn.addEventListener("click", (e) => {
            e.preventDefault()
            this.togglePlay()
        })
        this.togglePlayBtn.addEventListener("mouseenter", () => this.onMouseEnter())
        this.togglePlayBtn.addEventListener("mouseleave", () => this.onMouseLeave())
    }

    togglePlay() {
        // this.togglePlayBtn.style.display = "none"
        gsap.to(this.preview, { opacity: 0, duration: 0.5 })
        gsap.to(this.video, {
            opacity: 1, duration: 0.5, onComplete: () => {
                this.video.play()
            }
        })
    }

    onMouseEnter() {
        gsap.to(this.preview, { scaleX: 1.1, scaleY: 1.1, filter: 'grayscale(0%)', duration: 0.35, ease: 'steps(4)' })
    }

    onMouseLeave() {
        gsap.to(this.preview, { scaleX: 1.0, scaleY: 1, filter: 'grayscale(100%)', duration: 0.35, ease: 'steps(4)' })
    }

    togglePlay() {

    }
}