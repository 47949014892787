import { gsap } from "gsap"
export default class Frame {
    constructor() {
        this.scale = Math.random() * 2
        this.alpha = 0
        this.mainHeader = document.getElementById('main-header');
        this.toHome = document.getElementById('to-home');
    }

    show() {
        gsap.to(this, {
            scale: 1,
            alpha: 1,
            duration: 1,
            ease: "steps(12)"
        })
    }

    draw(_ctx) {
        this.context = _ctx;
        _ctx.save();
        _ctx.globalAlpha = this.alpha;

        // Obtener las dimensiones y el padding del elemento #main-header
        const mainHeader = document.getElementById('main-header');
        const mainHeaderStyles = window.getComputedStyle(mainHeader);
        const paddingX = parseFloat(mainHeaderStyles.paddingLeft) + parseFloat(mainHeaderStyles.paddingRight);
        const paddingY = parseFloat(mainHeaderStyles.paddingTop) + parseFloat(mainHeaderStyles.paddingBottom);
        const rectWidth1 = window.WIDTH - paddingX;
        const rectHeight1 = window.HEIGHT - paddingY;

        const rectX1 = parseFloat(mainHeaderStyles.paddingLeft);
        const rectY1 = parseFloat(mainHeaderStyles.paddingTop);


        // Obtener las dimensiones y la posición del elemento #toggle-play-btn
        const togglePlayBtn = document.getElementById('toggle-play-btn');
        const togglePlayBtnStyles = window.getComputedStyle(togglePlayBtn);
        const rectWidth2 = togglePlayBtn.offsetWidth;
        const rectHeight2 = togglePlayBtn.offsetHeight;
        const rectX2 = togglePlayBtn.offsetLeft;
        const rectY2 = togglePlayBtn.offsetTop;

        // Calcular el offset para centrar el contenido escalado
        const offsetX = (window.WIDTH - window.WIDTH * this.scale) / 2;
        const offsetY = (window.HEIGHT - window.HEIGHT * this.scale) / 2;

        _ctx.translate(offsetX, offsetY);
        _ctx.scale(this.scale, this.scale);

        // Dibujar el primer rectángulo
        _ctx.strokeRect(rectX1, rectY1, rectWidth1, rectHeight1);
        _ctx.restore();
        
        // Dibujar el segundo rectángulo
        _ctx.save();
        _ctx.translate(offsetX, offsetY);
        _ctx.scale(this.scale, this.scale);
        _ctx.globalAlpha = this.alpha * 0.5;
        _ctx.strokeRect(rectX2, rectY2, rectWidth2, rectHeight2);
        _ctx.restore();
    }


    drawLine(_ctx, x1, y1, x2, y2) {
        _ctx.beginPath();
        _ctx.moveTo(x1, y1);
        _ctx.lineTo(x2, y2);
        _ctx.stroke();
    }
}